'use strict';

import { isProdEnv, isBrowser } from '@/utils/environment';
let env_check = isProdEnv();

let actions = {
  track: (event, params) => {
    try {
      if (env_check && isBrowser) {
        window.fbq('track', event, params);
      }
    } catch (err) {
      console.error('error to fb track', err);
    }
  },
};

export let FacebookPixel = actions;
