import { getStateAbbreviation } from "./statesHelper";
import { getCookie } from 'cookies-next';

const PARTNER_ID_DANDY_WINE_SHOP = 'dandywineshop';
const PARTNER_ID_MLB = 'mlb-wine-brands';
const PARTNER_ID_SBG = 'sbg';
const PARTNER_ID_BEST_WINE_21 = 'bestwine21';
const PARTNER_ID_SIPJOY_OVER_BEST_WINE_21 = 'sipjoy_over_bestwine21';
const PARTNER_ID_COASTERS = 'coasters';
const PARTNER_ID_LEO_HILLINGER = 'leo-hillinger';
import { BEER_CAN_CHICKEN_ID } from '@/consts/siteConsts'

const BEST_WINE_21_LOCAL_NY_ZIPS = ['11020','11021','11022','11023','11024','11026','11027','11030','11050','11362','11363','11507','11530','11531','11535','11545','11547','11548','11576','11577','11579','11596','11599'];
let PartnersSupportClient = (function() {
  let partnerId;
  function setPartnerId(pId) {
    partnerId = pId;
  }
  function getPartnerId() {
    if (partnerId) {
      return partnerId;
    }
    const cpId = getCookie('COOKIE_PARTNER_ID');
    if (cpId) {
      //console.info('GETTING PARTNER ID FROM COOKIE');
      return cpId;
    }
    console.warn('no partner id found')
    return;
  }
  function isSipjoy() {
    return getPartnerId() === PARTNER_ID_SIPJOY_OVER_BEST_WINE_21;
  }
  return {
    PARTNER_ID_SBG: PARTNER_ID_SBG,
    PARTNER_ID_DANDY_WINE_SHOP: PARTNER_ID_DANDY_WINE_SHOP,
    PARTNER_ID_BEST_WINE_21: PARTNER_ID_BEST_WINE_21,
    PARTNER_ID_SIPJOY_OVER_BEST_WINE_21: PARTNER_ID_SIPJOY_OVER_BEST_WINE_21,
    PARTNER_ID_LEO_HILLINGER: PARTNER_ID_LEO_HILLINGER,
    PARTNER_ID_COASTERS: PARTNER_ID_COASTERS,
    ALL_PARTNERS: [
      PARTNER_ID_DANDY_WINE_SHOP,
      PARTNER_ID_BEST_WINE_21,
      PARTNER_ID_SIPJOY_OVER_BEST_WINE_21,
      PARTNER_ID_LEO_HILLINGER,
      PARTNER_ID_COASTERS,
      PARTNER_ID_MLB,
    ],
    setPartnerId: setPartnerId,
    getPartnerId: getPartnerId,
    isReady: () => { return partnerId ? true : false },
    isSipjoy: isSipjoy,
    getShipmentText: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_COASTERS:
          return 'TBD: Delivery is included for local orders over $35. For other locations, minimum is $99.'
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
        case PartnersSupportClient.PARTNER_ID_LEO_HILLINGER:
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return 'Delivery is included for local orders over $35. For other locations, minimum is $99.'
        default:
          return null
      }
    },
    flatShippingPrice: () => {
      return getPartnerId() === PARTNER_ID_SBG && 1200;
    },
    isBestWine21: () => {
      return getPartnerId() === PARTNER_ID_BEST_WINE_21
    },
    isShippingPriceBasedOnQuantity: () => {
      const pid = getPartnerId()
      return false
    },
    operatedByBestWine21: () => {
      const pid = getPartnerId()
      return (
        pid === PARTNER_ID_BEST_WINE_21 ||
        pid === PARTNER_ID_LEO_HILLINGER ||
        pid === PARTNER_ID_SIPJOY_OVER_BEST_WINE_21
      )
    },
    sideBarSide: () => {
      const pid = getPartnerId()
      return pid == PARTNER_ID_SBG ||
        pid == PARTNER_ID_SIPJOY_OVER_BEST_WINE_21
        ? 'right'
        : 'left'
    },
    storeName: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return 'Dandy Wine & Spirits'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return 'Best Wine 21'
        case PartnersSupportClient.PARTNER_ID_SBG:
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
        case PartnersSupportClient.PARTNER_ID_COASTERS:
        default:
          return 'Sipjoy'
      }
    },

    isLocalDelivery: ({ zip, state }) => {
      const stateCode = getStateAbbreviation(state)
      return stateCode === 'NY' && BEST_WINE_21_LOCAL_NY_ZIPS.includes(zip)
    },
    getBestWine21LocalZip: () => {
      return BEST_WINE_21_LOCAL_NY_ZIPS
    },
    getHeroImageName: (isSm) => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return 'dandywineshop_hero.png'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return isSm ? 'bestwine21hero.webp' : 'bestwine21heromobile.webp'
        case PartnersSupportClient.PARTNER_ID_SBG:
          return 'sbg_hero.png'
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
        case PartnersSupportClient.PARTNER_ID_COASTERS:
        default:
          return 'hero.webp'
      }
    },

    getLogo: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return '/dandywineshop_logo.png'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return '/best_wine_21_logo.png'
        case PartnersSupportClient.PARTNER_ID_SBG:
          return '/sipjoy-one-line.png'
          // return '/sbg_icon.png'
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
        case PartnersSupportClient.PARTNER_ID_COASTERS:
          return '/sipjoy-one-line.png'
        default:
          return '/sipjoy-one-line.png'
      }
    },
    getMetaPrviewImage: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return '/dandywineshop_logo.png'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return '/bestwine21_meta_preview.png'
        case PartnersSupportClient.PARTNER_ID_SBG:
        case PartnersSupportClient.PARTNER_ID_COASTERS:
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
          return '/meta_preview_hero.png'
        default:
          return '/meta_preview_hero.png'
      }
    },
    getMetaTitle: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return 'Dany Wine Shop'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return 'Best Wine 21'
        case PartnersSupportClient.PARTNER_ID_SBG:
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
          return 'sipjoy | Buy Direct'
        default:
          return 'sipjoy | Buy Direct'
      }
    },
    getMetaKeywords: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
        case PartnersSupportClient.PARTNER_ID_SBG:
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
        case PartnersSupportClient.PARTNER_ID_COASTERS:
          return 'wine, spirit'
        default:
          return 'wineries, market, farmes, wines'
      }
    },
    getMetaDescription: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case PartnersSupportClient.PARTNER_ID_COASTERS:
        case PartnersSupportClient.PARTNER_ID_SBG:
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
          return 'Your personal wine store'
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return 'Your local wine store in Williamsburg'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return 'Your local wine store in Manhasset, NY. Home delivery for over 1000 bottles'
        default:
          return 'Farmers market for wineries'
      }
    },
    getFavIcon: () => {
      switch (PartnersSupportClient.getPartnerId()) {
        case BEER_CAN_CHICKEN_ID:
          return 'https://res.cloudinary.com/dvcipe8td/image/upload/v1684307184/bccb_favicon_3cb6177a89.png'
        case PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP:
          return '/dandywineshop_fav_icon.png'
        case PartnersSupportClient.PARTNER_ID_BEST_WINE_21:
          return '/best21_favicon.png'
        case PartnersSupportClient.PARTNER_ID_SBG:
          return '/sbg_icon.png'
        
        case PartnersSupportClient.PARTNER_ID_COASTERS:
        case PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21:
          return '/fav_icon.png'
        default:
          return '/fav_icon.png'
      }
    },
    showHowItWorks: () => {
      return isSipjoy()
    },
    showWineClub: () => {
      return isSipjoy()
    },
    showFeaturedIn: () => {
      return (
        isSipjoy() ||
        getPartnerId() === PARTNER_ID_SIPJOY_OVER_BEST_WINE_21 ||
        getPartnerId() === PARTNER_ID_COASTERS
      )
    },
  }
})();

export default PartnersSupportClient;
