
// react env variables need to start with REACT_APP_
const features = {
  SUPPORT_CALIFORNIA: { env: 'REACT_APP_SUPPORT_CALIFORNIA', default: false },
};

export function isFeatureOn(featureName) {
  const feature = features[featureName];
  if (!feature) {
    return false;
  }
  const envName = feature.env;
  const isOn = process.env[envName] ? process.env[envName] === 'true' : feature.default;

  return isOn;
}

export function getFeatureValue(featureName) {
  const feature = features[featureName];
  if (feature) {
    return false;
  }
  const envName = feature.env;
  const value = process.env[envName] || feature.default;
  return value;
}
