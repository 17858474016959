import axios from 'axios';
import { getProductsServiceUrl } from '@/utils/environment';
import { FacebookPixel } from '@/utils/fbPixel';
import { getSelectedState} from '@/utils/statesHelper';

const SERVICE_URL = '';

function isResponseOk(response) {
  return response && response.status === 200 && !response?.data?.error;
}

export function genericPost(relativePath, data, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/${relativePath}`, data)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data)
      } else {
        onError(response?.data?.error)
      }
    })
    .catch(function(error) {
      console.log({ error })
      onError && onError()
    })
}


export function genericGet(relativePath, getParams, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/${relativePath}`, {
      params: getParams,
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getSearchTopics(getParams, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/search_topics`, {
      params: getParams,
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getShippingPrices({producerKey}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/admin/shipping_prices`, { params: {producerKey} })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function saveShippingPrices(data, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/shipping_prices`, data)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getProducerFeatures(onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/producer/features`)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getWineCharacteristics(onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/wine_characteristics`)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function createWineCharacteristic({name}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/wine_characteristic`, {name})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError(error);
    });
}

export function updateSearchTopic({topic, addNew}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/search_topic`, {topic, addNew})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError(error);
    });
}

export function createSearchableTags({name}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/searchable_tag`, {name})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError(error);
    });
}

export function updatedAffiliate({affiliate}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/marketing_data`, {affiliate})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.error({ error });
      onError && onError(error);
    });
}
export function updatedCoupon({coupon}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/coupon`, {coupon})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.error({ error });
      onError && onError(error);
    });
}

export function getSearchableTags(onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/searchable_tags`)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getMarketingData(onResponse, onError) {  
  axios
    .get(`${SERVICE_URL}/api/admin/marketing_data`)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response.data);
      } else {
        onError && onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getProducts({productCategory, filters, topicKey, pagination}, onResponse, onError) {
  const productsService = getProductsServiceUrl() || SERVICE_URL;
  axios
    .get(`${productsService}/api/products`, {
      params: {
        productCategory,
        filters,
        topicKey,
        pagination,
        returnFilters: true,
      },
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data)
      } else {
        onError(response?.data?.error)
      }
    })
    .catch(function(error) {
      console.error({ error, onError })
      onError && onError(error)
    })
}
export function getSearchWords({productCategory},onResponse, onError) {
  const productsService =  getProductsServiceUrl() || SERVICE_URL;
  axios
    .get(`${productsService}/api/products/search_words`,{
      params: { productCategory}})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data)
      } else {
        onError(response?.data?.error)
      }
    })
    .catch(function(error) {
      console.error({ error })
      onError && onError()
    })
}

export function getProductsForCheckout({shippingProducerKey, productsKey, includeShippingPrice}, onResponse, onError) {
  const shippingPriceForState = includeShippingPrice && getSelectedState();
  axios
    .post(`${SERVICE_URL}/api/order/products`, {
      shippingProducerKey,
      productsKey,
      shippingPriceForState
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          ...(response?.data || {})
        });
      
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}


export function createProducer({producer}, onResponse, onError) {
  console.log({createProducer:producer});
  axios
    .post(`${SERVICE_URL}/api/admin/producer`, {producer})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          producer: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError(error);
    });
}

export function createProduct({product}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/new_product`, {product})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          product: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError(error);
    });
}

export function updateProduct({product}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/update_product`, {product})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          product: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function updateProducer({producer}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/update_producer`, {producer})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          producer: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getProductPageInfo({producerKey, productKey}, onResponse, onError) {
  const shippingPriceForState = getSelectedState();
  axios
    .get(`${SERVICE_URL}/api/products/product_page_info`, {
      params: { producerKey, productKey, shippingPriceForState },
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse(response?.data);
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}
export function getProducerPageInfo({key, includeHidden}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/producer/page_info`, { params: { key, includeHidden } })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          producer: response?.data?.producer,
          products: response?.data?.products
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getProducerProducts({key, includeHidden}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/producer_products`, { params: { key, includeHidden } })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          producer: response?.data?.producer,
          products: response?.data?.products
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getProducer({key}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/producer`, { params: { key } })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({ producer: response?.data });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getAllProducts(data,onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/products`, { params: data })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          products: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getAllProducers(params, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/admin/all_producers`, {params})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          producers: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getHomepageHighlights(onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/homepage_highlights`)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          producers: response?.data?.producers,
          products: response?.data?.products
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getValidCoupon({couponCode, producerKey}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/order/validate_coupon`, {couponCode, producerKey})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          coupon: response?.data.coupon,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      onError && onError(error);
    });
}

export function createOrder(data, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/order`, data)
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          order: response?.data?.order,
          stripeCheckoutUrl: response?.data?.stripeCheckoutUrl
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      onError && onError(error);
    });
}


export function joinNewsletter({email, partnerId}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/newsletter`, {email, partnerId})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      onError && onError(error);
    });
}

export function contact({firstName, lastName, email, message, contactType, winery}, onResponse, onError) {
  FacebookPixel.track('Contact');
  axios
    .post(`${SERVICE_URL}/api/contact`, {firstName, lastName, email, message, contactType, winery})
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse && onResponse({
          response: response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      onError && onError(error);
    });
}


export function getOrders({includeTestOrders, status, email}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/admin/orders`, {
      params: {
        includeTestOrders, status, email
      },
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          orders: response?.data?.orders,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getOrder({orderKey, stripeSessionId}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/order`, {
      params: {
        orderKey,
        stripeSessionId
      },
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          ...response?.data,
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}

export function getPageContent({pageName}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/page_content`, {
      params: { pageName },
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          content: response?.data.content
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}


export function setPageShortcut({shortRef, href, remove, update}, onResponse, onError) {
  axios
    .post(`${SERVICE_URL}/api/admin/url_shortcut`, {shortRef, href, remove, update})
    .then(function(response) {
      console.log({res_s:response});
      if (isResponseOk(response)) {
        const data = response?.data || {};
        onResponse({
          ...data
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError(error);
    });
}

export function getPageShortcut({shortRef}, onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/admin/url_shortcut`, {
      params: { shortRef },
    })
    .then(function(response) {
      console.log({response});
      if (isResponseOk(response)) {
        const data = response?.data || {};
        onResponse({
          ...data
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}


export function getAllPageShortcuts(onResponse, onError) {
  axios
    .get(`${SERVICE_URL}/api/admin/url_shortcuts`)
    .then(function(response) {
      if (isResponseOk(response)) {
        const data = response?.data;
        onResponse({
          ...data
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}



export function updatePageContent({pageName, data}, onResponse, onError) {
  console.log({pageName, data})
  axios
    .put(`${SERVICE_URL}/api/page_content`, {
      pageName, data,
    })
    .then(function(response) {
      if (isResponseOk(response)) {
        onResponse({
          data: response?.data
        });
      } else {
        onError(response?.data?.error);
      }
    })
    .catch(function(error) {
      console.log({ error });
      onError && onError();
    });
}